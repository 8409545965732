:root {
  --orange: #FF8C32;
  --darkblue: #06113C;
  --darkgrey: #DDDDDD;
  --grey: #EEEEEE;
}
body {
  font-family: "Lato", sans-serif, 'Times New Roman',
    sans-serif;
  /*-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--darkblue);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
icon {
  color: var(--orange)
}
a{
  color: var(--orange);
  font-weight: bold;
}
div.h1{
  color: var(--orange);
  font-weight: bold;
}
strong{
  color: var(--orange);
}





.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  color: var(--darkblue);
  font-size: 12px;
  background-color: rgb(239, 242, 249);
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

#top {
  top: -40px;
  left: -50%;
}

.bottom {
  top: 25px;
  left: -50%;
}

#left {
  top: -8px;
  right: 120%;
}

#right {
  top: -8px;
  left: 120%;
}

.hover-text {
  position: relative;
  display: inline-block;
  text-align: center;
}

@media print {
  @page {
    margin: 0;
    padding: 40px;
    page-break-after: auto;
  }
}


@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    page-break-before: auto;
  }
}

@media print {
  .page-break {
    margin-top: 0px;
    display: block;
    page-break-before: auto;
  }
}

